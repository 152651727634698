<template>
  <CRow>
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="gradient-primary" :header="agents" text="No of Enrollees" style="min-height: 150px"> 
        <template #default>
          
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Enrollees"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="4">
        <CWidgetDropdown color="gradient-info" :header="active" text="Active Policies" style="min-height: 150px"> 
          <template #default>
            
          </template>
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="[1, 18, 9, 17, 34, 22, 11]"
              point-hover-background-color="info"
              :options="{ elements: { line: { tension: 0.00001 }}}"
              label="policies"
              labels="months"
            />
          </template>
        </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="gradient-success" :header="`${wallet}`" text="Wallet Balance" style="min-height: 150px"> 
        <template #default>
          
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="success"
            label="Value"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="gradient-warning" :header="cancelled" text="Discontinued Policies" style="min-height: 150px">
        <template #default>
          
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Policies"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <!-- <CCol sm="6" lg="4">
      <CWidgetDropdown color="gradient-danger" header="#50,000" text="Value of Loan Defaults" style="min-height: 150px">
        <template #default>
          
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="danger"
            label="Value"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="4">
      <CWidgetDropdown color="gradient-warning" header="100" text="Pending Loan Requests" style="min-height: 150px">
        <template #default>
          
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Pending Requests"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol> -->
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'
import axios from 'axios'
import url from '@/main'
export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  data(){
    return {
      agents: '',
      active: '',
      wallet: '',
      active: '',
      cancelled: ''
    }
  },
  created(){
    axios({url: `${url}/dashboard`, method: 'GET'})
    .then(res=>{
      this.agents = res.data.data.agent_count.toString()
      this.cancelled = res.data.data.cancelled_claim_count.toString()
      this.active = (res.data.data.gadget_policy_count + res.data.data.health_policy_count + res.data.data.life_policy_count).toString()
      this.wallet = (res.data.data.wallet_balance).toString()
    })
    axios({url: `${url}/agents`, method: 'GET'})
    .then(res=>{
      this.agents = res.data.data.totalRecord
    })
  }
}
</script>
